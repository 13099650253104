.btn
{
  border: none;
  background-color: $light-gray;
  font-weight: 500;
  padding: 0.5em;
  border-radius: $radius;
  max-width: 15em;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.btn:hover, .btn:focus
{
  cursor: pointer;
  background-color: rgb(197, 197, 197);
}

.btn:active, .btn:focus
{
  position: relative;
  top: 1px;
}

.btn--primary
{
  background-color: $success;
  color: white;
}

.btn--primary:hover
{
  background-color: $success;
}

.btn--danger
{
  background-color: $danger;
  color: white;
}

.btn--danger:hover
{
  background-color: $danger;
}

.btn--callToAction, .btn--callToAction:hover, .btn--callToAction:active, .btn--callToAction:focus
{
  background-color: $milennialpink;
  color: #590F37;
}

.btn--large
{
  padding: 0.8em $padding;
  font-size: 1.44em;
}

@media( max-width: 800px ){
  .btn--large
  {
    font-size: 1.2em;
  }
}