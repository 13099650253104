.alert
{
  display: block;
  padding: 0.5em 0.5em;
  border-radius: $radius;
}

.alert p
{
  padding: 0;
  margin: 0;
  line-height: 1.5em;
}

.alert--error
{
  background-color: $danger;
  color: white;
}

.alert--success
{
  background-color:#d1e5c2;
  color: $success;
}

.alert--personal
{
  border: 1px solid $light-gray;
}

.alert--personal p
{
  padding-left: 1.5em;
  position: relative;
}

.alert--personal p::before
{
  content: "";
  position: absolute;
  width: 1em; 
  height: 1em;
  background-image: url(../images/goodbytes_avatar.jpg);
  background-size: cover;
  left: 0;
  top: 0.2em;
}

.alert--avatar
{
  width: 1.2em;
  margin: 0 0.5em;  
}