.prevNext
{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr) );
  justify-items: center;
  padding: 2em;
  grid-gap: 1em;
}

.prevNext a
{
  display: block;
  padding: 1em 2em;
  text-decoration: none;
  background-color: $light-gray;
  color: $light-purple;
  font-weight: 500;
  transition: all ease-out 0.1s;
  border-radius: $radius;
  
}

.linkPrev
{
  justify-self: flex-start;
}

.linkNext
{
  justify-self: flex-end;
  text-align: right;
}

.prevNext a:hover, .prevNext a:focus
{
  background-color: $milennialpink;
  color: white;
}

@media(max-width: 44em){
  .linkPrev, .linkNext{
    justify-self: center;
  }
}