$dark: #04132E;
$light-purple: #8B7C98;
$bright-purple: #A652F1;
$milennialpink: #F92698;
$sun: #FCFD38;
$froggy: #04E762;
$light-gray: #EEEDED;
$danger: #D7263D;
$success: #4f772d;
$dark-blue: #04132E;
$blue: #3B1CFF;

$fontStackModern: 'Rubik', sans-serif;
$fontStackClassic: 'Roboto Mono', monospace;

$radius: 0.3em;
$gap: 1em;
$padding: 1.5em;

$minwidth: 60em;