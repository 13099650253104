.tiles
{
    display: grid;
    grid-template-columns: repeat(8, minmax(100px, 1fr));
    grid-template-rows: repeat(3, 100px);
}

.tile--pricing
{
    background-color: yellow;
    grid-column: 1 / span 2;
    grid-row: 2 / span 2;
    font-size: 2.6em;
    color: $blue;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tile--picture
{
    background-color: $bright-purple;
    grid-column: -4 / span 2;
    grid-row: 1 / span 2;
    background-image: url('../images/tile_jorre.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.tile--text
{
    background-color: $milennialpink;
    color: blue;
    font-weight: bold;
    text-align: center;
    font-size: 2.6em;
    grid-column: -5 / span 3;
    grid-row: 3 / span 1; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.tile--bright
{
    background-color: $milennialpink;
}

.tile--soft
{
    background-color: $bright-purple;
}

.tile--dark
{
    background-color: $blue;
}

@media( max-width: 815px ) {
    .tiles
    {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-template-rows: repeat(3, 100px);
    }    
}

@media ( max-width: 614px ){
    .tiles
    {
        font-size: 0.833rem;
    }

    .tile--text
    {
        grid-column: 3 / -1;
    }
}

@media (max-width: 514px){
    .tiles
    {
        font-size: 0.694rem;
    }

    .tile--picture
    {
        grid-column: 3 / -1;
    }
}

@media (max-width: 480px){

    .tiles
    {
        grid-template-columns: repeat( auto-fill, minmax( 50%, 1fr ) );
    }

    .tile--picture
    {
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
    }

    .tile--text
    {
        grid-column: 1 / -1;
    }

    .tile--pricing
    {
        grid-column: 2 / -1;
        grid-row: 2 / span 1;
    }

}