.nav
{
  background-color: $dark;
  display: grid;
  grid-template-columns: 8fr  4fr;
  padding: 1.5em;
  align-items: center;
  grid-gap: 1em;
}

.subnav
{
  text-align: right;
  display: flex;
  font-size: 0.833em;
  flex-direction: row;
  justify-content: flex-end;
}

.subnav a
{
  display: inline-block;
  margin-right: 1em;
  color: $milennialpink;
}

.nav__title
{
  color: $light-purple;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.logo
{
  margin-right: 0.5em;
  position: relative;
  top: 4px;
}

.logo img
{
  width: 30px;
}