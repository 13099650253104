
.error
{
  text-align: center;
}

.error__title
{
  color: $danger;
}

.error__subtext
{
  color: $dark;
}