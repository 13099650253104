.form
{
  
}

.form__field
{
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.5em 0;
}

.form__label
{
  font-family: $fontStackModern;
  font-weight: 500;
  padding: 0.5em 0;
}

.form input[type="text"], .form input[type="password"] {
  padding: 0.2em;
  font-family: $fontStackClassic;
}

.form input.maxwidth
{
  max-width:25em;
}

.fieldset
{
    border: 1px solid $light-gray;
    border-radius: $radius;
    margin-top: 1em;
    margin-bottom: 1em;
}