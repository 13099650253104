.courses
{
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  grid-gap: $gap;
}

.course
{
  padding: 2em;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
  min-height: 180px;
  max-width: 340px;
  display: grid;
  align-content: center;
  background-color: $light-purple;
  position: relative;
}

.course__type
{
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  display: inline-block;
  
  padding: 0.2em 0.4em;
  font-size: 0.7em;
  font-family: $fontStackClassic;
  border-radius: 4px;
}

.course__type--premium
{
  background-color: $sun;
  color: complement($sun);
}

.course__type--trial
{
  background-color: $blue;
  color: complement($blue);
}

.course__link
{
  color: $sun;
  font-family: $fontStackClassic;
  font-weight: 500;
  font-size: 1.728em;
  display: block;
  text-transform: uppercase;
  line-height: 1.5em;
}

.video
{
  background-image: url("../images/tile_stripes.jpg");
}

.videoNav
{
  padding: 1em;
  overflow-y: auto;
  box-shadow: inset 3px 5px 3px $light-gray;
}

.videoNav--autoplay
{
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  grid-gap: 0.5em;
}

.videoNav--autoplayText
{
  color: $light-purple;
  font-family: $fontStackClassic;
  font-size: 0.833em;
}

.videoNav--current
{
  color: $light-purple;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 0.5em;
}

.videoNav__items
{
  color: $light-purple;
  list-style-type: none;
  padding: 0;
}

.videoNav__item::before
{
  content: "";
  display: block;
  height: 1em;
  width: 1em;
  background-image: url(../images/icon_play.svg);
  position: absolute;
  background-size: cover;
  top: 0.2em;
}

.videoNav__item__current::before
{
  background-image: url(../images/icon_play_current.svg);
}

.videoNav__item
{
  margin: 1em 0;
  position: relative;
}

.videoNav__item a
{
  text-decoration: none;
  margin-left: 1.5em;
  color: $light-purple;
  line-height: 1.5em;
}

.videoNav__item__current
{
  margin-left: 0.2em;
}

.videoNav__item__current a
{
  color: $bright-purple;
  font-weight: 500;
}

.video__description
{
  padding: $padding;
  color: $light-purple;
  line-height: 1.5em;
}