.tag
{
    font-size: 0.833em;
    padding: 0.1em 0.2em;
    border-radius: 3px;
}

.tag--trial
{
    margin-left: 0.5em;
}