/* http://embedresponsively.com/ */
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.grid--main
{
  display: grid;
}

@media (min-width: $minwidth){
  .grid--main {
    grid-template-columns: 9fr 3fr;
  }
}

.grid--two-columns
{
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  grid-gap: $gap;
}

.padding
{
  padding: $padding;
}

.maxwidth
{
  max-width: 30em;
}

.maxwidth--xl
{
  max-width: 40em;
}

.maxwidth--xxl
{
  max-width: 60em;
}

.centered
{
  margin-left: auto;
  margin-right: auto;
}

.text--centered
{
  text-align: center;
}

.border
{
  border: 1px solid $light-gray;
}

.nomargin
{
  margin-top: 0;
}

.margin
{
  margin-top: 2em;
  margin-bottom: 2em;
}

.margin--bottom
{
  margin-bottom: 1em;
}

.hint
{
  font-size: 0.833em;
  color: $light-purple;
  padding-bottom: 0.5em;
  font-style: italic;
  line-height: 1.4em;
}

.highlight
{
  background-color: $sun;
  color: complement($sun);
}