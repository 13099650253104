.faqs
{
    padding: 5em 1em;
    background-color: $dark-blue;
    color: white;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2em;
}

.faq__title
{
    text-transform: uppercase;
    color: $sun;
    font-size: 1.44em;
}

.faq p
{
    font-family: $fontStackClassic;
    font-size: 0.833em;
}

.faq
{
    padding: 1em 2em;
}

@media( min-width: 55em ){
    .faqs{
        grid-template-columns: repeat(3, minmax(15em, 1fr));
    }

    .faq
    {
        padding: 0 0.5em;
    }
}