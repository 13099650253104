.pageHome
{
    background-color: $dark-blue;
}

.intro
{
    background-image: url("../images/tile_stripes_purple.jpg");
    padding: $padding*2 $padding 0 $padding;
}

.intro__title
{
    color: $sun;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    line-height: 1.3em;
}

.intro__topics {
    color: $dark-blue;
    text-align: center;
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    line-height: 1.3em;
    font-size: 1.5em;
}

.intro__image
{
    background-image: url('../images/intro_recording.gif');
    height: 435px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 3em;
}

@media( max-width:1024px ) 
{
    .intro__image
    {
        height: 435px;
    }
}

@media( max-width:800px ) 
{
    .intro__image
    {
        height: 380px;
    }

    .intro__title
    {
        font-size: 1.728em;
    }
}

@media( max-width:600px ) 
{
    .intro__image
    {
        height: 300px;
    }
}

@media( max-width:420px ) 
{
    .intro__image
    {
        height: 230px;
    }
}




