/* https://type-scale.com/ minor thirds */

// HELPERS
@import "variables";
@import "helpers";

// COMPONENTS
@import "_components/nav";
@import "_components/prevnext";
@import "_components/slider";
@import "_components/course";
@import "_components/alert";
@import "_components/button";
@import "_components/form";
@import "_components/intro";
@import "_components/faq";
@import "_components/testimonial";
@import "_components/tiles";
@import "_components/error";
@import "_components/tag";


html
{
  box-sizing: border-box;
}

body
{
 font-family: $fontStackModern;
}

h1, h2, h3, h4, h5
{
  font-family: $fontStackModern;
  font-weight: 500;
}

h1
{
  font-size: 2.074em;
}

h2
{
  font-size: 1.728em;
}

h3
{
  font-size: 1.44em;
}

h4
{
  font-size: 1.2em;
}

.instructions
{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $gap;
  font-family: $fontStackModern;
  color: $light-purple;
}

.instructions a
{
  text-decoration: none;
}